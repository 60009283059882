import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = ({data}) => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <p>404 message goes here.</p>
    </Layout>
  )
}

export default NotFoundPage
